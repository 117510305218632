<template>
  <page-wrapper
    :title="title"
    :text="text"
    link="Вернуться к списку"
    @click="$router.push({ name: 'Accreditations' })"
  >
    <el-form
      v-if="!isStep1"
      ref="addAccreditationForm"
      :model="form"
      :rules="rules"
      label-position="top"
      class="add-accreditation-form i-components"
      @submit.native.prevent
    >
      <el-row class="mb-15">
        <el-col v-if="user.organization" class="fs-20 mb-40 t-greyDark">
          Ваша организация:
          <span class="fw-b">{{ user.organization.name }}</span>
        </el-col>
        <el-col>
          <el-form-item prop="vendor_org_id" label="Заказчик">
            <el-select
              v-model="form.vendor_org_id"
              placeholder="Выберите из списка"
            >
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :sm="16" :xs="24">
          <el-form-item prop="contract_number" label="Номер договора">
            <el-input
              v-model="form.contract_number"
              autocomplete="nope"
              class="aaf-input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" align="center" justify="space-between">
        <el-col :sm="11" :xs="24">
          <el-form-item prop="contract_date" label="Дата заключения договора">
            <el-date-picker
              v-model="form.contract_date"
              type="date"
              :picker-options="{ firstDayOfWeek: 1 }"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="!form.is_unlimited" :sm="11" :xs="24">
          <el-form-item prop="expires_at" label="Договор действует до:">
            <el-date-picker
              v-model="form.expires_at"
              type="date"
              :picker-options="{ firstDayOfWeek: 1 }"
              value-format="timestamp"
              format="dd.MM.yyyy"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="mb-40 secondary-ch">
        <el-checkbox v-model="form.is_unlimited">
          договор бессрочный
        </el-checkbox>
      </el-row>
      <el-form-item>
        <i-button
          text="ЗАПРОСИТЬ АККРЕДИТАЦИЮ"
          :loading="loading"
          :disabled="loading"
          width="310"
          fs="18"
          @click="onClick"
        />
      </el-form-item>
    </el-form>
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import IButton from '@/components/Ui/Button'
import { titles, texts, accreditationAddRules } from './constants'

export default {
  name: 'AccreditationAdd',
  components: { PageWrapper, IButton },
  created() {
    this.fetchOrganizations({
      exclude_by_bidder_id: this.user.id,
      show_as_vendor: 1,
      'per-page': 9999
    }).then(res => {
      if (Array.isArray(res)) this.organizations = res
    })
  },
  data() {
    return {
      step: 0,
      rules: accreditationAddRules,
      form: {
        vendor_org_id: undefined,
        contract_number: '',
        contract_date: '',
        expires_at: '',
        is_unlimited: false
      },
      organizations: []
    }
  },
  computed: {
    ...mapGetters({ getLoading: 'accreditations/getLoading' }),
    loading() {
      return this.getLoading()
    },
    title() {
      return titles[this.step]
    },
    text() {
      return texts[this.step]
    },
    isStep1() {
      return this.step === 1
    }
  },
  methods: {
    ...mapActions({
      CREATE: 'accreditations/CREATE',
      fetchOrganizations: 'libs/fetchOrganizations'
    }),
    onClick() {
      this.$refs.addAccreditationForm.validate(valid => {
        if (valid) {
          const is_unlimited = this.form.is_unlimited ? 1 : 0
          const expires_at = this.form.expires_at / 1000
          const data = { ...this.form, expires_at, is_unlimited }
          if (is_unlimited) delete data.expires_at
          this.CREATE(data)
            .then(res => {
              if (res !== 'error') this.step = 1
            })
            .catch(err => this.errHandler(err))
        }
      })
    }
  }
}
</script>

<style lang="sass">
@import '~@/assets/styles/mixins.sass'

.add-accreditation-form
  max-width: 650px
  @include to(768px)
    padding: 0 20px
  .el-select
    max-width: 650px
  .aaf-input
    max-width: 450px
    @include to(768px)
      max-width: 100%
</style>
